<template>
  <div class="time">
    <div class='time-line'>
<!--      <search class="search" type="mini" @tap-item="handleInput"/>-->
      <div v-for='(item,index) in testList' :key="index" class='time-line-div'>
        <div>
          <div v-if="index%2===0" class="time-line-body">
            <div class="time-line-text">
              <div v-for="(item,index) in item.text" :key="index">{{item}}</div>
            </div>
            <div class="time-line-country country-left">
              <img v-if="item.src1" :src="item.src1" alt="">
              <div>
                <div style="font-size: 10px;color: #808080;text-align: center">{{item.month}}</div>
                <div style="font-size: 20px;font-weight: bold;text-align: center">{{item.day}}</div>
                <div style="font-size: 10px;color: #808080;text-align: center;">{{item.year}}</div>
              </div>
              <img v-if="item.src2" :src="item.src2" alt="">
            </div>
          </div>
          <div  v-if="index%2===0" class="triangle triangle-right"/>
        </div>
        <img src="@/assets/image/est/dotted-dot.png">
        <div>
          <div v-if="index%2!==0" class="triangle triangle-left"/>
          <div v-if="index%2!==0" class="time-line-body">
            <div class="time-line-country country-right">
              <img v-if="item.src1" :src="item.src1" alt="">
              <div>
                <div style="font-size: 10px;color: #808080;text-align: center">{{item.month}}</div>
                <div style="font-size: 20px;font-weight: bold;text-align: center">{{item.day}}</div>
                <div style="font-size: 10px;color: #808080;text-align: center">{{item.year}}</div>
              </div>
              <img v-if="item.src2" :src="item.src2" alt="">
            </div>
            <div class="time-line-text">
              <div v-for="(item,index) in item.text" :key="index">{{item}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class='img-dotted' :style="{ height:  dottedWidth + 'px'}"></div>
      <div v-if="testList.length === 0" style="width: 100%; height: 500px; display: flex; flex-direction: column; justify-content: center; align-items: center; font-size: 18px;">
        <img src="@/assets/image/est/no-msg.png">
      </div>
    </div>
    <div style='margin-top:20px;'/>
  </div>
</template>

<script>
// import Search from '@/views/en/btcest/components/search'
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'TimeLine',
  components: {
    // Search
  },
  props: {
    KeyWord: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      testList: [],
      listQuery: {}
    }
  },
  mounted () {
    this.getHistoricalUpdate()
  },
  computed: {
    dottedWidth () {
      return this.testList.length * 170
    }
  },
  watch: {
    'KeyWord' (nval, oval) {
      if (nval !== oval) {
        this.listQuery.KeyWord = nval
        this.getHistoricalUpdate()
      }
    }
  },
  methods: {
    getHistoricalUpdate () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetWebHistoricalUpdate', this.listQuery).then(() => {
        this.testList = this.$store.getters.etcRows.Data
        this.getIcon()
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch(err => {
        console.log(err)
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    // handleInput (item) {
    //   if (this.$store.getters.token) {
    //     this.listQuery.KeyWord = item
    //     this.getHistoricalUpdate()
    //   } else {
    //     this.openLoginModal()
    //   }
    // },
    getIcon () {
      this.testList.forEach(item => {
        if (item && item.country) {
          const arr = []
          const temp = item.country
          for (let i = 0; i < temp.length; i++) {
            if (temp[i] === '') continue
            arr.push(temp[i].replaceAll(' ', ''))
          }
          if (arr.length > 0) {
            item.src1 = require(`@/assets/image/est/national-flag/${arr[0]}.png`)
            if (arr.length > 1) {
              item.src2 = require(`@/assets/image/est/national-flag/${arr[1]}.png`)
            }
          }
        }
        return null
      })
    }
  }
}
</script>

<style lang="scss" scoped>

$cell-width: 482px;

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.time {
  margin-top: 20px;
  width: 1200px;
  height: 750px;
  overflow-y: scroll;
}

.time-line{
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.time-line-div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  font-size: 14px;
}

.time-line-div > div{
  display: flex;
  justify-content: center;
  align-items: center;
  width: $cell-width;
}

.time-line-body{
  width: $cell-width;
  min-height: 119px;
  border-radius: 7px;
  font-size: 14px;
  background-color: rgba(242,242,242,1);
  display: flex;
  justify-content: space-between;
}

.time-line-text{
  padding: 20px;
  font-size: 14px;
}

.time-line-text > div:last-child{
  margin-top: 8px;
}

.time-line-country{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 62px;
}
.time-line-country > img{
  width: 62px;
  min-height: 34px;
}

.country-left {
  border-left: 1px solid #ffffff;
}

.country-left > img:first-child{
  border-top-right-radius: 7px;
  -webkit-border-top-right-radius: 7px;
}

.country-left > img:last-child{
  border-bottom-right-radius: 7px;
  -webkit-border-bottom-right-radius: 7px;
}

.country-right {
  border-right: 1px solid #ffffff;
}

.country-right > img:first-child{
  border-top-left-radius: 7px;
  -webkit-border-top-left-radius: 7px;
}

.country-right > img:last-child{
  border-bottom-left-radius: 7px;
  -webkit-border-bottom-left-radius: 7px;
}

.time-line-div > img {
 z-index: 2;
}

.triangle {
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
}

.triangle-right {
  margin-right: 15px;
  border-left: 15px solid rgba(242,242,242,1);
}

.triangle-left {
  margin-left: 15px;
  border-right: 15px solid rgba(242,242,242,1);
}

.img-dotted{
  position:absolute;
  width:4px;
  height:750px;
  top:0;
  left: 598px;
  z-index: 1;
  background:url('../../../../../assets/image/est/dotted.png');
}

.search{
  position:absolute;
  top:0;
  right: 80px;
}
</style>
