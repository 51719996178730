<template>
  <div class="item-box">
    <div class="h1">Timetable</div>
    <search style="align-self: flex-end;margin-right: 100px" class="search" type="mini" @tap-item="handleInput"/>
    <time-line :key-word="KeyWord"/>
  </div>
</template>

<script>
import TimeLine from '@/views/en/btcest/components/timeline'
import Search from '@/views/en/btcest/components/search'
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'BTCEstHistoricalUpdate',
  components: {
    TimeLine,
    Search
  },
  data () {
    return {
      KeyWord: ''
    }
  },
  mounted () {

  },
  methods: {
    handleInput (item) {
      if (this.$store.getters.token) {
        this.KeyWord = item
      } else {
        this.openLoginModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

$cell-width: 200px;
$cell-height: 50px;

.item-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.h1{
  font-size: 30px;
  font-weight: bold;
  padding: 40px 0 10px 0;
}

// 表头
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  height: $cell-height;
  border-radius: 8px;
  background: #e6e6e6;
  font-weight: bold;
  font-size: 16px;
}

.header > div {
  min-width: $cell-width;
  text-align: center;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.table-box {
  margin-top: 10px;
  height: 690px;
  overflow-y: scroll;
}

.table-item {
  height: $cell-height;
  border-radius: 8px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  box-shadow: 0 1px 10px 1px #e1e1e1;
}

.table-item > span {
  width: $cell-width;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}
</style>
